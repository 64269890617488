

































































































// vue
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { PrPage } from '@/mixin';
import { PrPageHeader, PrUploading, PrUploaded } from '@/component';

import { ReportModel, ReportOverrideRequest } from '@/store';

import { I8Form, I8Icon } from 'i8-ui';

// Icon library
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';

// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSpinner, faFileCode);

export default Vue.extend({
  mixins: [PrPage],

  components: {
    PrPageHeader,
    PrUploading,
    PrUploaded,
    I8Icon,
    I8Form,
  },

  data() {
    return {
      formModel: { report: {} } as { report: File },
      formValidation: { isValid: false },
      isDownloading: false,
      uploadPercentage: 0,
      string: {
        title: 'Report actions for',
        reportDownload: {
          heading: 'Download Report',
          subHeading: 'Submitted reports only',
          helperMsg:
            'Submitted reports can be downloaded in XML for reference or amendment.',
          downloadBtn: 'Download Report',
        },
        reportOverride: {
          heading: 'Upload Amended Report',
          subHeading: 'Rejected reports only',
          uploadFieldLabel: 'Amended report (.xml)',
          helperMsg:
            'Rejected reports need to be manully amended before re-sending to the regulator.',
          uploadBtn: 'Resubmit Report',
          cancelBtn: 'Cancel',
        },
      },
    };
  },

  computed: {
    ...mapGetters('report', ['reportById']),

    reportId(): string {
      return this.$route.params.id;
    },

    report(): ReportModel {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.reportById(this.reportId);
    },

    showDownload(): boolean {
      return this.report.allow_download;
    },

    showUpload(): boolean {
      return this.report.allow_override;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formSchema(): any {
      return {
        elements: {
          report: {
            type: 'file',
            icon: 'file-code',
            label: this.string.reportOverride.uploadFieldLabel,
            required: true,
            disabled: !this.showUpload,
            accept: '.xml',
          },
        },
      };
    },
  },

  methods: {
    ...mapActions('report', ['reportGet', 'reportDownload', 'reportOverride']),

    /**
     * Load all data for this view
     */
    async loadData(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      if (!this.reportId) {
        return;
      }

      vm.stateLoading();
      try {
        await vm.reportGet(this.reportId);
        vm.stateSuccess();
      } catch (e) {
        vm.stateError(e);
      }
    },

    /**
     * Download a report xml file
     */
    async downloadReport(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      if (!this.reportId) {
        return;
      }
      try {
        this.isDownloading = true;
        await vm.reportDownload(this.reportId);
      } catch (error) {
        console.error(error);
      } finally {
        this.isDownloading = false;
      }
    },

    /**
     * Override the generated report file with a new one
     */
    async overrideReport(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      if (!this.reportId) {
        console.error('Unable to override report: report id not found');
        return;
      }

      if (!this.formValidation.isValid) {
        // the button should be disabled so if were here, something broke
        // the form should display an error message for the user
        return;
      }

      vm.stateUploading();

      const payload = {
        reportId: this.reportId,
        report: this.formModel.report,
        onUploadProgress: (p: ProgressEvent) => {
          this.uploadPercentage = Math.round((p.loaded / p.total) * 100);
        },
      } as ReportOverrideRequest;

      try {
        await vm.reportOverride(payload);
      } catch (e) {
        vm.stateError(e);
        return;
      }

      this.formModel = { report: {} as File };

      // successful upload
      vm.stateUploaded();

      // hide success message after a short while
      setTimeout(vm.stateSuccess, 2000);
    },
  },

  watch: {
    reportId: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      handler(newVal: any) {
        this.loadData();
      },
      immediate: true,
    },
  },
});
